import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import React from "react";
import Parser from "../components/parser.js";

const NotFoundPage = (props) => {
  return (
    <Layout infos={{ postType: "404" }} seo={{}}>
      <Parser content={ props.data.wpTemplate?.content || "" } />
    </Layout>
  );
};
export const pageQuery = graphql`
  query NotFound {
    wpTemplate(template_reference: {referenceTemplate: {eq: "page_404"}}) {
      content
    }
  }
`;
export default NotFoundPage;
